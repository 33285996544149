import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrTurekLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Turek"
    institution="Dr. Turek"
    referralCode="DRTUREK"
    physicianURL="https://www.theturekclinic.com/meet-dr-turek-fertility-urologist/"
  />
)

export default DrTurekLandingPage
